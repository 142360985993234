import PropTypes from 'prop-types';
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ParishInfo from 'components/parishInfo';
import { getCurrencyFormat, isValidObject } from '../../common/utils';
import * as globals from '../../common/globals';
import withConfirmationModal from '../../views/basket/kiosk/withConfirmationModal';
import useInstallments from '../../hooks/useInstallments';
import useFeatures from '../../hooks/common/useFeatures';

const styles = (theme) => ({
  image: {
    width: '140px',
    height: '100px',
    borderRadius: '4px',
  },
  basketItemRoot: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '1.6',
    letterSpacing: '0.3px',
    marginRight: '-8px',
  },
  itemButtonContainer: {
    display: 'flex',
  },
  itemHeaderLink: {
    color: theme.palette.primary.main,
  },
  processingFee: {
    color: theme.palette.primary.main,
  },
  itemText: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.8',
    letterSpacing: '0.3px',
  },
  quantitySelect: {
    marginLeft: '5px',
    textAlign: 'right',
  },
  leftMemo: {
    textAlign: 'left',
    maxWidth: '50%',
  },
  rightMemo: {
    textAlign: 'right',
    maxWidth: '50%',
  },
});

/**
 * Placeholder component for items in the basket. Will need to be expanded with whatever details we decide to put in the
 * side basket view widget
 */
export function BasketItem(props) {
  const {
    item,
    locationId,
    onDelete,
    showProcessingFees,
    processingFee,
    classes,
    onChangeQuantity,
  } = props;
  const {
    campaignDetails: { id, title },
    transactionData,
  } = item;

  const { t } = useTranslation();
  const { Installment: installmentFF, CreditParish: ffCreditParish } =
    useFeatures();
  const onDeleteClick = () => {
    onDelete(item);
  };

  const data = transactionData[Object.keys(transactionData)[0]];
  const { frequency, quantity, itemAmount, memoLine, recurringOption, parish } =
    data;

  const { memoLine: memoLineDetails } = item?.campaignDetails
    ?.transactionItems[0] ?? { memoLine: null };

  const formattedStartDate =
    data.startDate && moment(data.startDate).format('L');
  const formattedEndDate = data.endDate && moment(data.endDate).format('L');

  const isInstallment =
    installmentFF &&
    recurringOption === globals.PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT;

  const displayParish = ffCreditParish && isValidObject(parish);

  const {
    totalAmount,
    quantity: installmentQuantity,
    isSameRate,
    firstInstallment,
    rate,
  } = useInstallments(
    data.amount,
    frequency,
    data.startDate,
    data.endDate,
    showProcessingFees,
    processingFee,
  );

  const getAmountLabel = (labelFrequency, formattedFrequency) => {
    if (isInstallment)
      return t('basket.installments', {
        totalInstallments: isSameRate
          ? installmentQuantity
          : installmentQuantity - 1,
        frequency: labelFrequency,
      });
    // Handles the shouldn't-happen case where a removed frequency is
    // returned by the backend
    if (formattedFrequency === '') {
      return 'Unknown Frequency Total';
    }

    const totalFrequencyLabelMap = {
      [globals.PAYMENT_FREQUENCY_ONE_TIME_NOW]: t('basket.oneTimeTotal'),
      [globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE]: t('basket.oneTimeTotal'),
      [globals.PAYMENT_FREQUENCY_TWICE_A_MONTH]: t('basket.biMonthly'),
      default: t('basket.totalWithFrequency', {
        frequency: t(`basket.${labelFrequency}`),
      }),
    };

    return (
      totalFrequencyLabelMap[labelFrequency] || totalFrequencyLabelMap.default
    );
  };

  const getDateLabel = (dateType) => {
    if (frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE)
      return t('basket.scheduledDate');

    if (isInstallment) return t(`basket.installments.${dateType}`);

    return t(`addDonation.${dateType}`);
  };

  const renderFirstInstallment = () => {
    if (isInstallment && !isSameRate)
      return (
        <div className={classes.itemText}>
          <span id="FirstInstallment">
            {t('basket.installments.first')}
            {showProcessingFees && (
              <span
                className={classes.processingFee}
                data-testid="FirstInstallment-ProcessingFee"
              >
                {` + ${processingFee}%`}
              </span>
            )}
          </span>
          <span id="FistInstallment">
            {getCurrencyFormat(firstInstallment)}
          </span>
        </div>
      );
    return null;
  };
  // Since it's possible that removed frequencies still may
  // be returned by the backend, we need to at least make sure
  // the app doesn't throw an error in the shouldn't-happen-case
  // where one is returned
  const formattedFrequency = globals.PAYMENT_FREQUENCY_DISPLAY_NAMES[frequency]
    ? globals.PAYMENT_FREQUENCY_DISPLAY_NAMES[frequency].shortDescription
    : '';

  let amount = parseFloat(data.amount, 10);

  if (showProcessingFees) {
    amount += amount * (processingFee / 100);
  }

  const quantityChoices = range(1, globals.MAX_PAYMENT_ITEMS + 1); // From 1 to max quantity

  const displayDates =
    frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW || isInstallment;

  return (
    <div>
      <div className={classes.basketItemRoot}>
        <div className={classes.itemHeader}>
          <div id="headerTitle">
            <Link to={`/${locationId}/campaign/${id}`}>
              <Typography variant="body2">{title}</Typography>
            </Link>
          </div>
          <div className={classes.itemButtonContainer}>
            <Button
              id="basket-item-delete-button"
              className={classes.itemHeaderLink}
              color="primary"
              onClick={onDeleteClick}
            >
              <Typography>{t('basket.remove')}</Typography>
            </Button>
          </div>
        </div>
        {quantity && getCurrencyFormat && (
          <Typography component="div">
            <div className={classes.itemText}>
              <div>
                <span id="amountLabel">{t('addDonation.quantity')}:</span>
                <TextField
                  className={classes.quantitySelect}
                  select
                  value={quantity}
                  InputProps={{ disableUnderline: true }}
                  onChange={(event) => {
                    onChangeQuantity(event.target.value);
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      variant: 'menu',
                      PaperProps: { style: { maxHeight: 150, maxWidth: 60 } },
                    },
                  }}
                >
                  {quantityChoices.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <span id="amount">{getCurrencyFormat(itemAmount)} ea</span>
            </div>
          </Typography>
        )}
        <Typography component="div">
          {renderFirstInstallment()}
          <div className={classes.itemText}>
            <span id="AmountLabel">
              {getAmountLabel(frequency, formattedFrequency)}
              {showProcessingFees && !!processingFee && (
                <span
                  className={classes.processingFee}
                  data-testid="AmountLabel-ProcessingFee"
                >{` + ${processingFee}%`}</span>
              )}
            </span>
            <span id="amount">
              {isInstallment
                ? getCurrencyFormat(rate)
                : getCurrencyFormat(amount)}
            </span>
          </div>
          {displayDates && (
            <>
              <div className={classes.itemText}>
                <span>{getDateLabel('startDate')}</span>
                <span id="startDate">{formattedStartDate}</span>
              </div>
              {formattedEndDate && (
                <div className={classes.itemText}>
                  <span>{getDateLabel('endDate')}</span>
                  <span data-testid="endDate">{formattedEndDate}</span>
                </div>
              )}
            </>
          )}
        </Typography>
        {isInstallment && (
          <Typography component="div" variant="body2">
            <div className={classes.itemText}>
              <span
                id="InstallmentTotal"
                data-testid="InstallmentTotal-ProcessingFee"
              >
                {t('basket.installments.total')}
                {showProcessingFees && (
                  <span className={classes.processingFee}>
                    {` + ${processingFee}%`}
                  </span>
                )}
              </span>
              <span id="installmentTotal">
                {getCurrencyFormat(totalAmount)}
              </span>
            </div>
          </Typography>
        )}

        {displayParish ? <ParishInfo name={parish.parishName} /> : null}

        {memoLine && (
          <Typography component="div">
            <div className={classes.itemText}>
              <span className={classes.leftMemo}>
                {memoLineDetails?.textField}
              </span>
              <span id="memoTextField" className={classes.rightMemo}>
                {memoLine}
              </span>
            </div>
          </Typography>
        )}
      </div>
    </div>
  );
}

BasketItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  showProcessingFees: PropTypes.bool,
  processingFee: PropTypes.number,
};

BasketItem.defaultProps = {
  showProcessingFees: false,
  processingFee: null,
};

export default withConfirmationModal(withStyles(styles)(BasketItem));
