import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';

import * as globals from '../../../common/globals';
import * as ValidationUtils from '../../../common/validation/AddDonationValidationUtils';
import * as PaymentFixedAmountUtils from '../../../common/validation/PaymentFixedAmountUtils';
import {
  getInitialValues,
  getInitialTouched,
} from '../../../utils/CampaignUtils';

import styles from './styles';
import SubmitButton from '../submitButton/SubmitButton';
import ContinueButtons from '../continueButtons/ContinueButtons';

function AddDonationForm({
  classes,
  campaignDetails,
  inBasket,
  transactionData,
  addToBasket,
  renderTransactionItem,
  showCard,
  enableDefaultFrequency,
  initialAmount,
  initialParish,
  isParishRequired,
}) {
  const SubmitButtons = window.ReactNativeWebView
    ? ContinueButtons
    : SubmitButton;

  function getValidationSchema() {
    if (campaignDetails.type === globals.CAMPAIGN_TYPE.GIFT) {
      return ValidationUtils.createSchema(
        campaignDetails.transactionItems,
        isParishRequired,
      );
    }
    return PaymentFixedAmountUtils.createSchema(
      campaignDetails.transactionItems,
      isParishRequired,
    );
  }

  function getValuesAmount(values) {
    return Object.values(values).reduce((total, item) => {
      const newTotal = total + parseFloat(item.amount || 0);
      return newTotal;
    }, 0);
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      onSubmit={addToBasket}
      initialValues={getInitialValues({
        campaignDetails,
        transactionData,
        enableDefaultFrequency,
        initialAmount,
        initialParish,
      })}
      initialTouched={getInitialTouched({
        campaignDetails,
        transactionData,
      })}
      validationSchema={getValidationSchema()}
    >
      {(formikProps) => {
        return (
          <Form>
            <Typography
              id="sidebar-title"
              variant="h4"
              className={classes.sidebarTitle}
            >
              {campaignDetails.title}
            </Typography>
            {campaignDetails.transactionItems &&
              campaignDetails.transactionItems.map((item) =>
                renderTransactionItem(item, formikProps),
              )}
            <SubmitButtons
              id="addToBasketButton"
              className={
                showCard
                  ? classes.addToBasketButton
                  : classes.addToBasketButtonNoCard
              }
              amount={getValuesAmount(formikProps.values)}
              type={campaignDetails?.type}
              inBasket={inBasket}
              formik={formikProps}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

AddDonationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  campaignDetails: PropTypes.object.isRequired,
  inBasket: PropTypes.bool.isRequired,
  transactionData: PropTypes.object.isRequired,
  addToBasket: PropTypes.func.isRequired,
  renderTransactionItem: PropTypes.func.isRequired,
  showCard: PropTypes.bool.isRequired,
  enableDefaultFrequency: PropTypes.bool.isRequired,
  initialAmount: PropTypes.number,
  isParishRequired: PropTypes.bool.isRequired,
  initialParish: PropTypes.object,
};

AddDonationForm.defaultProps = {
  initialAmount: undefined,
  initialParish: undefined,
};

export default withStyles(styles)(AddDonationForm);
