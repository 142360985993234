import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, Card, CardContent } from '@material-ui/core';
import { withRouter } from 'react-router';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'hooks';
import { addBasketItem } from '../../actions/basket';
import * as globals from '../../common/globals';
import OpenAmount from './transactionItems/OpenAmount';
import PaymentFixedAmount from './transactionItems/PaymentFixedAmount';
import {
  hasError,
  getErrorMessage,
  getPaymentId,
} from '../../utils/CampaignUtils';
import { enqueueSnackbarAndLogError } from '../../actions/notifications';
import { basketItemsMatch, getFloatFromText } from '../../common/utils';
import AddDonationForm from './form';
import ConfirmationDialog from '../common/ConfirmationDialog';
import styles from './styles';
import { useTrackRecurrence } from '../../hooks/googleAnalyticHooks/googleAnalyticHooks';

export function AddDonation({
  classes,
  items,
  campaignDetails,
  addBasketItemConnect,
  history,
  locationId,
  enqueueSnackbarAndLogErrorConnect,
  loggedIn,
  inBasket,
  showCard,
  enableDefaultFrequency,
  iframe,
  isParishRequired,
  parishes,
}) {
  const { amt, parish } = useQueryParams();
  const initialAmount = amt ? getFloatFromText(amt, 2) : undefined;
  const parishId = Number(parish);
  const initialParish = parishId
    ? parishes.find((p) => p.parishId === parishId)
    : undefined;

  const [state, setState] = useState({
    errorModalOpen: false,
    errorModalMessage: null,
    // item data passed in from cart. We use cloneDeep to ensure we're not modifying the redux store contents with our operations,
    // but we might be able to do this using just layered shallow copies. But since we're only modifying the store once at the end
    // and the only item being changed is the current campaign, this approach might be fine. We should look into this more later.
    cartData: null,
    initialAmount,
  });

  const { t } = useTranslation();

  useEffect(() => {
    const cartData = items.filter(
      (item) =>
        item.campaignDetails.id === campaignDetails.id &&
        item.campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT,
    );
    const data =
      cartData.length > 0
        ? cloneDeep(cartData[0])
        : {
            campaignDetails: campaignDetails || {},
            transactionData: {},
          };

    setState((prevState) => {
      return {
        ...prevState,
        cartData: data,
      };
    });
  }, [items, campaignDetails]);

  const sendEventToGA = useTrackRecurrence();

  const setErrorModalOpen = ({ isOpen, errorMessage }) => {
    setState((prevState) => {
      return {
        ...prevState,
        errorModalOpen: isOpen,
        errorModalMessage: isOpen ? errorMessage : null,
      };
    });
  };

  /**
   * render a transaction item in the sidebar from the transaction items
   */
  const renderTransactionItem = (details, formikProps) => {
    const itemData = state.cartData.transactionData[details.fundId] || {};
    switch (details.type) {
      case globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT:
        return (
          <OpenAmount
            id="OpenAmount"
            transactionDetails={details}
            itemData={itemData}
            key={details.fundId}
            formikProps={formikProps}
          />
        );
      case globals.TRANSACTION_ITEM_TYPES.PAYMENT_FIXED_AMOUNT:
        return (
          <PaymentFixedAmount
            key={details.fundId}
            details={details}
            {...formikProps}
          />
        );
      default:
        return <Fragment key={details.fundId} />;
    }
  };

  /**
   * Catches the submit event from the form, compiles the data that's been passed up by each child transaction item, and sends it through redux to be added to the basket.
   * Then redirects to the basket page.
   * If the user pressed "Continue to payment" they are taken to that page
   */
  const addToBasket = ({ toPayment = false, ...values }, actions) => {
    const { id } = campaignDetails;
    const { frequency } = values[id];

    sendEventToGA(loggedIn, frequency, 'add');

    const newBasketItem = { campaignDetails, transactionData: values };

    // const storedItems = storageUtils.getBasketItemsFromStorage(locationId);
    if (newBasketItem.campaignDetails.type !== globals.CAMPAIGN_TYPE.PAYMENT) {
      const matchingItems = items.filter((it) =>
        basketItemsMatch(it, newBasketItem),
      );
      if (matchingItems.length > 0) {
        setErrorModalOpen({
          isOpen: true,
          errorMessage: t('basket.itemAlreadyInBasket'),
        });
        actions.setSubmitting(false);
        return;
      }
    } else {
      // Payment Validations
      const paymentId = getPaymentId(newBasketItem);
      const existingPaymentItems = items.filter((item) =>
        Boolean(item.transactionData[paymentId]),
      );
      if (hasError(newBasketItem, existingPaymentItems[0] || {})) {
        setErrorModalOpen({
          isOpen: true,
          errorMessage: getErrorMessage(
            newBasketItem,
            existingPaymentItems[0] || {},
          ),
        });
        actions.setSubmitting(false);
        return;
      }
    }

    try {
      addBasketItemConnect(locationId, newBasketItem);
      // if the "continue to payment" button was pressed navigate directly to payment
      if (toPayment) {
        history.push(`/${locationId}/${globals.PAYMENT_PATH}`);
      } else {
        // redirect to the basket page
        history.push(`/${locationId}/${globals.BASKET_PATH}`);
      }
    } catch (e) {
      enqueueSnackbarAndLogErrorConnect(e, {
        variant: globals.NOTIFICATION_ERROR,
        message: e.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!state.cartData) return <></>;

  const formProps = {
    campaignDetails,
    inBasket,
    transactionData: state.cartData?.transactionData || {},
    addToBasket,
    renderTransactionItem,
    showCard,
    enableDefaultFrequency,
    initialAmount,
    isParishRequired,
    initialParish,
  };

  return (
    <>
      {showCard ? (
        <Card
          className={`${classes.container} ${iframe ? classes.inIframe : ''}`}
        >
          <CardContent className={classes.cardContent}>
            <AddDonationForm {...formProps} />
          </CardContent>
        </Card>
      ) : (
        <AddDonationForm {...formProps} />
      )}
      <ConfirmationDialog
        title={t('basket.errorModal.title')}
        message={state.errorModalMessage}
        submitButtonText={t('app.gotIt')}
        onSubmit={() => setErrorModalOpen({ isOpen: false })}
        isOpen={state.errorModalOpen}
      />
    </>
  );
}

AddDonation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  enqueueSnackbarAndLogErrorConnect: PropTypes.func.isRequired,
  campaignDetails: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  addBasketItemConnect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  inBasket: PropTypes.bool.isRequired,
  showCard: PropTypes.bool.isRequired,
  iframe: PropTypes.bool.isRequired,
  enableDefaultFrequency: PropTypes.bool,
  loggedIn: PropTypes.bool,
  isParishRequired: PropTypes.bool.isRequired,
  parishes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AddDonation.defaultProps = {
  loggedIn: false,
  enableDefaultFrequency: false,
};

const mapStateToProps = (state) => ({
  items: state.basket.items,
  iframe: state.frame.inIframe,
  loggedIn: state.session?.loggedIn,
  isParishRequired:
    state.features.features.CreditParish && state.location.parishes?.length > 0,
  parishes: state.features.features.CreditParish ? state.location.parishes : [],
});

const mapDispatchToProps = (dispatch) => ({
  addBasketItemConnect: (locationId, item, keepExistingCampaignItems) => {
    dispatch(addBasketItem(locationId, item, keepExistingCampaignItems));
  },
  enqueueSnackbarAndLogErrorConnect: (error, notification) => {
    return dispatch(enqueueSnackbarAndLogError(error, notification));
  },
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AddDonation);
