import { shape, func, bool, arrayOf, object } from 'prop-types';
import { withStyles, Typography, Divider } from '@material-ui/core';
import { Field, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import useFeatures from 'hooks/common/useFeatures';
import AutocompleteDropdown from 'components/common/AutocompleteDropdown';
import { useState } from 'react';
import * as globals from '../../../common/globals';
import Dropdown from '../../common/Dropdown';
import { getCurrencyFormat } from '../../../common/utils';
import {
  LOCATION_PAGE_LAYOUTS,
  MAX_PAYMENT_ITEMS,
} from '../../../common/globals';
import TextFieldWithCharCount from '../../common/TextFieldWithCharCount';
import MemoFieldsDropdown from './MemoFieldsDropdown';
import { useFixedAmountStyles } from './styles';

const stylesOverrides = () => ({
  quantity: {
    color: '#222222',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 0,
  },
});

const options = Array.from(Array(MAX_PAYMENT_ITEMS).keys()).map((item) => ({
  value: item + 1,
  text: (item + 1).toString(),
}));

export function PaymentFixedAmount({
  details,
  values,
  errors,
  touched,
  setFieldValue,
  features,
  isExpressLayout,
  parishes,
}) {
  const { t } = useTranslation();
  const classes = useFixedAmountStyles({
    layout: isExpressLayout
      ? LOCATION_PAGE_LAYOUTS.EXPRESS
      : LOCATION_PAGE_LAYOUTS.DETAIL,
  });
  const { CreditParish: creditParishFF } = useFeatures();
  const [parishValue, setParishValue] = useState(
    values[details.fundId]?.parish?.parishName || '',
  );

  const onQuantityChange = (e) => {
    if (!details.fundId) {
      return;
    }
    setFieldValue(`${details.fundId}`, {
      ...values[details.fundId],
      quantity: e.target.value,
      amount: (
        parseFloat(values[details.fundId].itemAmount) * e.target.value
      ).toString(),
    });
  };

  const getLabelText = () => {
    let formattedText = details.memoLine.textField;
    formattedText += details.memoLine.responseRequired
      ? ` (${t('addDonation.responseRequired')})`
      : ` (${t('addDonation.responseOptional')})`;
    return formattedText;
  };

  const showMemoFieldsDropDown =
    features?.TileMemoDropdown &&
    !details.isTextFieldResponseType &&
    details.memoFields?.length > 0;
  const displayCreditParish = creditParishFF && parishes?.length > 0;

  const orderedMemoFields = details.memoFields?.sort((a, b) =>
    a.ordinal > b.ordinal ? 1 : -1,
  );
  const handleParishChange = (value) => setParishValue(value);

  const getPriceDescription = () => {
    if (isExpressLayout) {
      return (
        <Typography className={classes.price}>
          {t('accountPayment.priceEach', {
            price: getCurrencyFormat(details.price),
          })}
        </Typography>
      );
    }
    return (
      <Typography className={classes.price}>
        {getCurrencyFormat(details.price)}
      </Typography>
    );
  };

  return (
    <>
      {getPriceDescription()}
      {!isExpressLayout && (
        <Typography className={classes.quantity}>
          {t('app.quantity')}
        </Typography>
      )}
      <Field
        id="donation-quantity"
        data-testid="donation-quantity"
        label={isExpressLayout ? t('accountPayment.selectQuantity') : null}
        aria-label={t('accountPayment.selectQuantity')}
        name={`${details.fundId}.quantity`}
        component={Dropdown}
        options={options}
        onChange={onQuantityChange}
        className={classes.inputField}
        helperTextClass={classes.inputHelperText}
      />

      {(details.memoLine.showTextField || displayCreditParish) && (
        <Divider variant="middle" className={classes.dividerStyleFix} />
      )}

      {displayCreditParish && (
        <AutocompleteDropdown
          name={`${details.fundId}.parish`}
          label={t('transactions.action.selectParish')}
          className={classes.wideInputField}
          options={parishes}
          readOnly={parishes?.length < globals.FILTER_ITEMS_NUMBER}
          getOptionLabel={(option) => option?.parishName || ''}
          getOptionSelected={(option, value) =>
            value?.parishId === option?.parishId
          }
          onChange={(selectedValue) => {
            setFieldValue(`${details.fundId}.parish`, selectedValue || '');
          }}
          selectedValue={values[details.fundId]?.parish || null}
          value={parishValue}
          onInputChange={handleParishChange}
          errorMessage={
            getIn(touched, `${details.fundId}.parish`) &&
            errors[details.fundId]?.parish
          }
        />
      )}

      {details.memoLine.showTextField && !showMemoFieldsDropDown && (
        <>
          <Typography className={classes.dateTitle} variant="caption">
            {getLabelText()}
          </Typography>
          <Field
            id="donation-memoline"
            name={`${details.fundId}.memoLine`}
            variant="outlined"
            autoComplete="off"
            maxLength={50}
            maxRows={2}
            multiline
            className={classes.wideInputField}
            helperTextClass={classes.inputHelperText}
            component={TextFieldWithCharCount}
          />
        </>
      )}
      {details.memoLine.showTextField && showMemoFieldsDropDown && (
        <Field
          id="memo-fields"
          textFieldLabel={`${t('accountPayment.select')} ${getLabelText()}`}
          name={`${details.fundId}.memoLine`}
          component={MemoFieldsDropdown}
          maxLength={50}
          memoFields={orderedMemoFields}
          className={classes.wideInputField}
          helperTextClass={classes.inputHelperText}
        />
      )}
    </>
  );
}

PaymentFixedAmount.propTypes = {
  details: shape({}).isRequired,
  values: shape({}).isRequired,
  errors: shape({}).isRequired,
  touched: shape({}).isRequired,
  setFieldValue: func.isRequired,
  features: shape({}).isRequired,
  isExpressLayout: bool,
  parishes: arrayOf(object),
};

PaymentFixedAmount.defaultProps = {
  isExpressLayout: false,
  parishes: [],
};

const mapStateToProps = (state) => ({
  features: state.features?.features,
  parishes: state.location.parishes,
});

export default compose(
  withStyles(stylesOverrides),
  connect(mapStateToProps),
)(PaymentFixedAmount);
