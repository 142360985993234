import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

function ParishInfo({ name }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentName = name ?? '';

  return (
    <Typography component="div" className={classes.text}>
      <span data-testid="parishInfoHead">{t('parish.info.head')}</span>
      <span data-testid="parishInfoName" className={classes.name}>
        {currentName}
      </span>
    </Typography>
  );
}

ParishInfo.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ParishInfo;
