import * as globals from '../../common/globals';

/* Backend org details to a frontend repersentation of org details.
TODO: May want to throw an error if the data doesn't match the expected format such that a proper representation cannot be created */
export const transformLocationDetails = (response) => {
  /* map of backend enums for card brands to our own internal strings for them */
  const backendCardBrandNames = {
    visa: globals.CARD_TYPE_VISA,
    mastercard: globals.CARD_TYPE_MASTERCARD,
    discover: globals.CARD_TYPE_DISCOVER,
    amex: globals.CARD_TYPE_AMEX,
  };

  if (response?.status === 200 && response?.data?.data) {
    const details = response.data.data;
    const included = response.data.included ? response.data.included[0] : null;

    const transformed = {
      id: details.id,
      name: details.name || '',
      homePageUrl: details.homePageUrl || '',
      address: details.address || {},
      contact: details.contact || {},
      isAutomation: false,
      paymentMethods: {
        creditCard: details.donationOptions.paymentMethods.creditCard || null,
        ach: details.donationOptions.paymentMethods.ach || null,
      },
      primaryColor: details.primaryColor || '',
      allowSocialSharing: details.allowSocialSharing,
      logoImageUrl: details.logoImageUrl,
      displayName: details.displayName,
      defaultView: details.defaultView,
    };

    if (
      transformed.homePageUrl &&
      !transformed.homePageUrl.startsWith('http')
    ) {
      transformed.homePageUrl = `http://${transformed.homePageUrl}`;
    }

    if (
      details.donationOptions.paymentMethods.creditCard &&
      details.donationOptions.paymentMethods.creditCard.allowedCardBrands
    ) {
      transformed.paymentMethods.creditCard.allowedCardBrands =
        details.donationOptions.paymentMethods.creditCard.allowedCardBrands
          .filter((brand) => {
            return backendCardBrandNames[brand.toLowerCase()];
          })
          .map((brand) => {
            return backendCardBrandNames[brand.toLowerCase()];
          });
    }

    if (included?.organization) {
      transformed.parentOrgName = included.organization.dbaName || '';
      transformed.isAutomation = !!included.organization.isAutomation;
      transformed.idsPayerTenant = included.organization.idsPayerTenant || '';
      transformed.pcct = included.organization.pcct || '';
      transformed.organizationId = included.organization.id || '';
      transformed.enableDefaultFrequency =
        included.organization.enableDefaultFrequency || false;
      transformed.applyFeeByDefault = included.organization.applyFeeByDefault;
      transformed.orgFIPEnabled = included.organization.hasFIPEnabled;
      transformed.parishes = included.organization.parishes;
    }

    response.data = transformed;
  }
  return response;
};

export default { transformLocationDetails };
