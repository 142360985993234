import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.white,
    '& .MuiFormHelperText-root': {
      margin: 0,
      padding: '4px 0 0 12px',
      // backgroundColor: '#FAFAFA',
      backgroundColor: 'transparent',
    },
  },
  helperTextClass: {
    marginLeft: 12,
    '& .MuiFormHelperText-root': {
      margin: 0,
      padding: '4px 0 0 12px',
      backgroundColor: '#FAFAFA',
    },
  },
}));
