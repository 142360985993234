import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  text: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.8',
    letterSpacing: '0.3px',
  },
  name: {
    textAlign: 'right',
    maxWidth: '50%',
  },
});

export default useStyles;
