export const HOME_PATH = 'home';
export const ERROR_PATH = 'error';
export const CAMPAIGN_PATH = 'campaign';
export const BASKET_PATH = 'basket';
export const PAYMENT_PATH = 'payment';
export const MPPAYMENT_PATH_LOGIN = 'mp_payment/login';
export const MPPAYMENT_LOGIN__PATH = '/login';
export const MPPAYMENT_PATH = 'mp_payment';
export const REVIEW_PATH = 'review';
export const EXTERNAL_REVIEW_PATH = 'external/review';
export const THANK_YOU_PATH = 'thankyou';
export const PROFILE_PATH = 'profile';
export const TRANSACTIONS_PATH = 'transactions';
export const TRANSACTIONS_THANKS_PATH = 'thanks';
export const SETTINGS_PATH = 'settings';
export const GENERAL_ERROR_PATH = 'error';
export const BASKET_STORAGE_NAME = 'basketItems';
export const COLOR_STORAGE_NAME = 'cachedColors';
export const PAYMENT_OPTIONS_STORAGE_NAME = 'paymentOptions';
export const MIGRATED_TRANSACTIONS_STORAGE_NAME = 'migratedTransactions';
export const ORIGIN_STORAGE_NAME = 'origin';
export const LAYOUT_VIEW_STORAGE_NAME = 'layout';
export const SESSION_COOKIE_NAME = 'accessToken';
export const TENANT_COOKIE_NAME = 'sessionTenant';
export const CSRF_COOKIE_NAME = 'csrfToken';
export const PAYMENT_ID_STORAGE_NAME = 'paymentId';
export const SUBMITTED_PAYMENT_ID_STORAGE_NAME = 'submittedPaymentId';
export const SUBMITTED_USER_DETAILS_STORAGE_NAME = 'submittedUserDetails';
export const REDIRECT_ID_STORAGE_NAME = 'redirectId';
export const SESSION_ID_STORAGE_NAME = 'sessionId';
export const LAST_ACTIVE_TIME_STORAGE_NAME = 'lastActive';
export const FEATURES_STORAGE_NAME = 'features';
export const CAMPAIGN_TYPE = { GIFT: 'Gift', PAYMENT: 'Payment' };
export const TRANSACTION_ITEM_TYPES = {
  OPEN_AMOUNT: 'OPEN_AMOUNT',
  PAYMENT_FIXED_AMOUNT: 'PAYMENT_FIXED_AMOUNT',
};
export const INFORMATION_ITEM_TYPES = { OPEN_ENTRY: 'OPEN_ENTRY' };
export const CREDIT_CARD = 'creditCard';
export const ACH = 'ach';
export const SWIPE = 'swipe';
export const CREDIT_CARD_TOKENIZE = 'card';
export const ACH_TOKENIZE = 'echeck';
export const ACCOUNT_CHECKING = 'checking';
export const ACCOUNT_SAVINGS = 'savings';
export const CARD_TYPE_VISA = 'visa';
export const CARD_TYPE_MASTERCARD = 'mastercard';
export const CARD_TYPE_DISCOVER = 'discover';
export const CARD_TYPE_AMEX = 'amex';
export const IDS_DOMAIN = 'id.vancoplatform.com';
export const NEW_PAYMENT_METHOD = 'NEW_PAYMENT_METHOD';
export const TRANSACTIONS_SCHEDULED = 'scheduled';
export const TRANSACTIONS_HISTORY = 'history';
export const TRANSACTIONS_ACTION_NEEDED = 'actionneeded';
export const TRANSACTIONS_ORIGIN = 'origin';
export const PAYMENT_FREQUENCY_MONTHLY = 'Monthly';
export const PAYMENT_FREQUENCY_WEEKLY = 'Weekly';
export const PAYMENT_FREQUENCY_ONE_TIME_NOW = 'OneTimeNow';
export const PAYMENT_FREQUENCY_ONE_TIME_FUTURE = 'OneTimeFuture';
export const PAYMENT_FREQUENCY_BI_WEEKLY = 'BiWeekly';
export const PAYMENT_FREQUENCY_TWICE_A_MONTH = 'BiMonthly';
export const PAYMENT_FREQUENCY_QUARTERLY = 'Quarterly';
export const PAYMENT_FREQUENCY_ANNUALLY = 'Annually';
export const PAYMENT_FREQUENCY_OPTIONS = {
  RECURRING: 'recurring',
  ONCE: 'once',
  INSTALLMENT: 'installment',
};
export const NOTIFICATION_ERROR = 'error';
export const NOTIFICATION_INFO = 'info';
export const NOTIFICATION_SUCCESS = 'success';
export const NOTIFICATION_WARNING = 'warning';
export const IDS_LOGIN_REQUIRED = 'login_required';
export const IDS_INTERACTION_REQUIRED = 'interaction_required';
export const IDS_NON_REQUIRED = 'login_not_required'; // Prevent non-interactive login
export const IDS_STATE_TOKEN = 'stateToken';
export const IDS_FROM_LOG_OUT = 'logout';
export const IDS_POST_REDIRECT = 'post-redirect';
export const CXP_AUTHORIZATION_TYPE_IDS = 'IDS';
export const ACH_ADDRESS_NOT_PROVIDED = 'No Address Provided';
export const IDLE_STORAGE_NAME = 'wasIdle';
export const IDS_STATE = 'state';
export const CSRF_HEADER_NAME = 'x-csrf-token';
export const COOKIELESS_HEADER_NAME = 'Cookieless-Request';
export const VP_CAMPAIGNS_PAYMENT_HEADER_NAME = 'vp-campaigns-payment-header';
export const AZURE_POST_REDIRECT = '/post-redirect-b2c';

export const LEFT_NAV_SIZE_LARGE = 270;
export const LEFT_NAV_SIZE_SMALL = 80;

export const LOCATION_PAGE_LAYOUTS = {
  DETAIL: 'detail',
  EXPRESS: 'express',
};

export const ERROR_NOT_FOUND = 'notFound';
export const ERROR_MISSING_CAMPAIGN = 'missingCampaign';
export const ERROR_SERVER = 'serverError';

export const CARD_DISPLAY_NAMES = {
  [CARD_TYPE_VISA]: 'Visa',
  [CARD_TYPE_DISCOVER]: 'Discover',
  [CARD_TYPE_MASTERCARD]: 'Mastercard',
  [CARD_TYPE_AMEX]: 'American Express',
};

export const PAYMENT_FREQUENCY_DISPLAY_NAMES_DEPRECATED = {
  [PAYMENT_FREQUENCY_ONE_TIME_NOW]: {
    fullDescription: 'frequency.fullDescription.onceNow',
    shortDescription: 'One-Time',
    displayOrder: 1,
  },
  [PAYMENT_FREQUENCY_ONE_TIME_FUTURE]: {
    fullDescription: 'frequency.fullDescription.onceFutureDate',
    shortDescription: 'One-Time',
    displayOrder: 2,
  },
  [PAYMENT_FREQUENCY_WEEKLY]: {
    fullDescription: 'frequency.fullDescription.everyWeek',
    shortDescription: 'Weekly',
    displayOrder: 3,
  },
  [PAYMENT_FREQUENCY_BI_WEEKLY]: {
    fullDescription: 'frequency.fullDescription.everyTwoWeeks',
    shortDescription: 'Biweekly',
    displayOrder: 4,
  },
  [PAYMENT_FREQUENCY_MONTHLY]: {
    fullDescription: 'frequency.fullDescription.monthly',
    shortDescription: 'Monthly',
    displayOrder: 5,
  },
};

export const PAYMENT_FREQUENCY_DISPLAY_NAMES = {
  [PAYMENT_FREQUENCY_ONE_TIME_NOW]: {
    fullDescription: 'frequency.fullDescription.onceNow',
    shortDescription: 'One-Time',
    displayOrder: 1,
  },
  [PAYMENT_FREQUENCY_ONE_TIME_FUTURE]: {
    fullDescription: 'frequency.fullDescription.onceFutureDate',
    shortDescription: 'One-Time',
    displayOrder: 2,
  },
  [PAYMENT_FREQUENCY_WEEKLY]: {
    fullDescription: 'frequency.fullDescription.everyWeek',
    shortDescription: 'Weekly',
    displayOrder: 3,
  },
  [PAYMENT_FREQUENCY_BI_WEEKLY]: {
    fullDescription: 'frequency.fullDescription.everyTwoWeeks',
    shortDescription: 'Biweekly',
    displayOrder: 4,
  },
  [PAYMENT_FREQUENCY_TWICE_A_MONTH]: {
    fullDescription: 'frequency.fullDescription.twiceAMonth',
    shortDescription: 'BiMonthly',
    displayOrder: 5,
  },
  [PAYMENT_FREQUENCY_MONTHLY]: {
    fullDescription: 'frequency.fullDescription.monthly',
    shortDescription: 'Monthly',
    displayOrder: 6,
  },
  [PAYMENT_FREQUENCY_QUARTERLY]: {
    fullDescription: 'frequency.fullDescription.quarterly',
    shortDescription: 'Quarterly',
    displayOrder: 7,
  },
  [PAYMENT_FREQUENCY_ANNUALLY]: {
    fullDescription: 'frequency.fullDescription.annually',
    shortDescription: 'Annual',
    displayOrder: 8,
  },
};

export const RECURRING_PAYMENT_OPTIONS = [
  PAYMENT_FREQUENCY_WEEKLY,
  PAYMENT_FREQUENCY_BI_WEEKLY,
  PAYMENT_FREQUENCY_TWICE_A_MONTH,
  PAYMENT_FREQUENCY_MONTHLY,
  PAYMENT_FREQUENCY_QUARTERLY,
  PAYMENT_FREQUENCY_ANNUALLY,
];

// required actions
export const REQUIRED_ACTIONS_PATCH_COMPLETED = 'Completed';
// Session length before we clear basket items/attempt to log out user.
// We should try to keep it in sync with the IDS token expiration length if that value changes.
export const SESSION_LENGTH_MILLISECONDS = 1000 * 60 * 60; // 1000 * seconds * minutes
export const MAX_PAYMENT_ITEMS = 10;
export const MIN_TRANSACTION_AMOUNT = 3.0;

export const POCKET_PLATFORM_ORIGIN = 'MP-PPM';
export const VALID_ORIGINS = [POCKET_PLATFORM_ORIGIN];
export const CXP_ERROR_CODES = {
  ADDRESS_AND_POSTAL_CODE_MISSMATCH: '7053',
  POSTAL_CODE_MISSMATCH: '7054',
  ADDRESS_MISSMATCH: '7055',
  INVALID_CVV: '7056',
};

export const VANCO_ONLINE_APP_TITLE = 'Vanco Online';

export const INVALID_LOCATIONS_ID_BLACKLIST = [
  'verification_success',
  'verification-success',
  'error',
  'mp_payment',
];

export const FILTER_ITEMS_NUMBER = 10;
export const CAMPAIGN_ID_QUERY_PARAM = 'campaignId';
export const AMOUNT_QUERY_PARAM = 'amt';
export const PARISH_QUERY_PARAM = 'parish';

export const APP_LANGUAGE_COOKIE_KEY = 'i18nextLang';

export const APP_LANGUAGES = {
  SPANISH: 'es',
  ENGLISH: 'en',
};

export const VANCO_VIDEO_TYPE = { VIDEO: 'videos' };

export const FAITH_FORWARD_EVENT_TYPE = {
  DONATE_CLICKED_MESSAGE: 'donateClicked',
};

export const POST_MESSAGE_EVENT_NAME = 'message';
