import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TruncateMarkup from 'react-truncate-markup';
import {
  CardMedia,
  Grid,
  LinearProgress,
  withStyles,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import AddDonation from '../../components/addDonation/AddDonation';
import { useGetCampaignById, useQueryParams } from '../../hooks';
import { renderDescriptionTileHTML } from '../../utils/TileDescriptionUtils';
import ReturnToOpportunities from '../../components/navigation/returnToOpportunities/ReturnToOpportunities';

import styles from './styles';
import SocialShareButton from '../../components/common/SocialShareButton';
import SocialShareDialog from '../../components/common/SocialShareDialog';
import usePageTitle from '../../hooks/common/usePageTitle';
import { getLayoutViewFromStorage } from '../../common/storageUtils';
import {
  AMOUNT_QUERY_PARAM,
  CAMPAIGN_ID_QUERY_PARAM,
  HOME_PATH,
  LOCATION_PAGE_LAYOUTS,
  PARISH_QUERY_PARAM,
} from '../../common/globals';

export function Expanded(props) {
  const {
    match: {
      params: { locId, campaignId },
    },
    basketIds,
    classes,
    loc,
    features,
  } = props;

  const history = useHistory();
  const { amt, parish } = useQueryParams();
  const { t } = useTranslation();

  // When express view is selected
  useEffect(() => {
    const storedLayout = getLayoutViewFromStorage(locId);
    if (
      features.VOExpressView &&
      (storedLayout === LOCATION_PAGE_LAYOUTS.EXPRESS ||
        (!storedLayout && loc?.defaultView === LOCATION_PAGE_LAYOUTS.EXPRESS))
    ) {
      history.push(
        `/${locId}/${HOME_PATH}?${CAMPAIGN_ID_QUERY_PARAM}=${campaignId}${
          amt ? `&${AMOUNT_QUERY_PARAM}=${amt}` : ''
        }${parish ? `&${PARISH_QUERY_PARAM}=${parish}` : ''}`,
      );
    }
  }, [
    loc?.defaultView,
    history,
    locId,
    campaignId,
    features.VOExpressView,
    amt,
    parish,
  ]);

  const { data: campaignDetails, status } = useGetCampaignById(campaignId);
  usePageTitle(campaignDetails?.title);
  const [shouldTrunctate, setShouldTrunctate] = useState(true);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isInWebView = Boolean(window.ReactNativeWebView);
  const displayText = () => setShouldTrunctate(false);

  // Quill editor insert this default string: "[{"insert":"\n"}]"
  const descriptionNotEmpty =
    campaignDetails &&
    campaignDetails.description &&
    campaignDetails.description.length > 17;

  const showSingleColumnLargeScreen =
    !isSmallScreen &&
    campaignDetails &&
    campaignDetails.image?.url === null &&
    !descriptionNotEmpty;

  const openShareDialog = () => {
    setShareDialogOpen(true);
  };

  const closeShareDialog = () => {
    setShareDialogOpen(false);
  };

  useEffect(() => {
    // Verify that the campaign is associated to the current location.
    if (
      campaignDetails &&
      !campaignDetails.locationIds?.includes(locId) &&
      campaignDetails.locationIds?.filter((str) => str.includes(locId))
        .length === 0
    ) {
      history.push(`/error`);
    }
  }, [campaignDetails, campaignId, history, locId]);

  const renderImage = () =>
    campaignDetails.image &&
    campaignDetails.image.url && (
      <Grid item id="expanded-image-item" className={classes.expandedImageItem}>
        {/* need to have a placeholder image url or we get a console warning. This can be changed later. */}
        <CardMedia
          className={classes.image}
          data-testid="campaign-image"
          image={campaignDetails.image.url || 'https://via.placeholder.com/150'}
        />
      </Grid>
    );

  const renderDescription = () =>
    descriptionNotEmpty && (
      <Grid item id="text-item" className={classes.textContainer}>
        <div data-testid="main-text" className={classes.descriptionContainer}>
          {shouldTrunctate && isSmallScreen ? (
            <TruncateMarkup
              lines={4}
              ellipsis={
                <>
                  ...
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <span onClick={displayText} className={classes.readMore}>
                    {t('read.more')}
                  </span>
                </>
              }
            >
              <div>
                {renderDescriptionTileHTML(campaignDetails.description)}
              </div>
            </TruncateMarkup>
          ) : (
            renderDescriptionTileHTML(campaignDetails.description)
          )}
        </div>
      </Grid>
    );

  const renderRightPane = () => (
    <Grid
      item
      id="transaction-item-container"
      xs={12}
      md={showSingleColumnLargeScreen ? 12 : 5}
    >
      <AddDonation
        locationId={locId}
        campaignDetails={campaignDetails}
        inBasket={basketIds.includes(campaignId)}
        showCard={!isSmallScreen}
        enableDefaultFrequency={loc.enableDefaultFrequency}
      />
      {loc.allowSocialSharing &&
        (!isSmallScreen && !isInWebView ? (
          <div
            data-testid="share-site-btn-container"
            className={classes.shareSiteButtonContainer}
          >
            <Typography className={classes.shareSiteHeader}>
              {`${t('socialShare.header')} ${campaignDetails.title}`}
            </Typography>
            <Typography className={classes.shareSiteText}>
              {t('socialShare.subheader.truncated')}
            </Typography>
            <div
              className={classes.shareSiteButton}
              data-testid="share-site-btn"
            >
              <SocialShareButton onClick={openShareDialog} fullWidth outlined />
            </div>
          </div>
        ) : (
          <div className={classes.shareSiteButton} data-testid="share-site-btn">
            <SocialShareButton onClick={openShareDialog} fullWidth outlined />
          </div>
        ))}
    </Grid>
  );

  const renderLink = () => (
    <ReturnToOpportunities variant="default" useWordMore />
  );

  return status === 'loading' ? (
    <div className={classes.progressHolder}>
      <LinearProgress
        data-testid="expanded-progress"
        className={classes.expandedProgress}
      />
    </div>
  ) : (
    <>
      {campaignDetails && (
        <Grid
          container
          className={classes.expandedContainer}
          direction="column"
          alignItems="center"
        >
          <Grid
            item
            id="expanded-main"
            xs={12}
            sm={10}
            lg={8}
            className={
              showSingleColumnLargeScreen
                ? classes.singleColumn
                : classes.expandedItem
            }
          >
            <Grid container justifyContent="center" direction="row" spacing={5}>
              {isSmallScreen ? (
                <Grid item id="expanded-body" xs={12} md={7}>
                  <Grid
                    container
                    id="expanded-text"
                    direction="column"
                    spacing={3}
                  >
                    {renderImage()}
                    {renderRightPane()}
                    {renderDescription()}
                    {renderLink()}
                  </Grid>
                </Grid>
              ) : (
                <>
                  {!showSingleColumnLargeScreen && (
                    <Grid item id="expanded-body" xs={12} md={7}>
                      <Grid
                        container
                        id="expanded-text"
                        direction="column"
                        spacing={3}
                      >
                        {renderImage()}
                        {renderDescription()}
                        {renderLink()}
                      </Grid>
                    </Grid>
                  )}
                  {renderRightPane()}
                  {showSingleColumnLargeScreen && renderLink()}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {loc.allowSocialSharing && (
        <SocialShareDialog
          open={shareDialogOpen}
          campaign={campaignDetails}
          handleClose={closeShareDialog}
        />
      )}
    </>
  );
}

Expanded.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  loc: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
};

Expanded.defaultProps = {};

export default withStyles(styles)(Expanded);
