/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import useStyles from './styles';
import FormHelperTextBottom from '../FormHelperTextBottom';

function AutocompleteDropdown({
  onChange,
  label,
  readOnly,
  selectedValue,
  getOptionLabel,
  value,
  onInputChange,
  errorMessage,
  ...props
}) {
  const classes = useStyles();

  const handleInputChange = (event, inputValue) => {
    onInputChange(inputValue);
  };

  return (
    <Autocomplete
      id="autocomplete"
      {...props}
      onChange={(event, selected) => onChange(selected)}
      value={selectedValue}
      getOptionLabel={getOptionLabel}
      inputValue={selectedValue ? getOptionLabel(selectedValue) : value}
      onInputChange={handleInputChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly,
            }}
            label={label}
            fullWidth
            className={classes.field}
            variant="outlined"
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />
        );
      }}
    />
  );
}

AutocompleteDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  selectedValue: PropTypes.object,
  value: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

AutocompleteDropdown.defaultProps = {
  className: '',
  readOnly: false,
  selectedValue: null,
  value: '',
  errorMessage: '',
};

export default AutocompleteDropdown;
